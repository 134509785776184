
/** 
* MEDIRIS - by Logoscorp
* 03/24/2015
*/
body{font-size: 13px;}

@font-face {
    font-family: ico_mediris;
    src: url(../fonts/ico_mediris.woff);
    src: url(../fonts/ico_mediris.ttf);
}

@font-face {
    font-family: "Open Sans", sans-serif;
    src: url(../fonts/OpenSans-Regular.ttf);
}

body.login {
    background: #f1f1f1;

}

body {
    background: #f5f5f5;
    font-family: "Open Sans", sans-serif;
}

table tr{

    background-color: lightgray;
    color: #121212!important;
}

table tr:hover{

    background-color: gray!important;
}

.k-pager-wrap{

    color: #121212!important;
}

.k-grid-content{

    position: static!important;
}

div.normal-table table thead tr th, div#grid table thead tr th, div#grid1 table thead tr th, div#grid2 table thead tr th, div#grid3 table thead tr th, div#grid4 table thead tr th, div#grid5 table thead tr th {
  background-color: #a4aeb9;
  border: 0;
  color: #121212!important;
  font-weight: bold;
  font-size: 1em;
  text-transform: none;
  padding-left: 24px;
  position: relative;
}

div.normal-table table thead tr th a, div#grid table thead tr th a, div#grid1 table thead tr th a, div#grid2 table thead tr th a, div#grid3 table thead tr th a, div#grid4 table thead tr th a, div#grid5 table thead tr th a {
  color: #121212!important;
  font-weight: bold;
  font-size: 1em;
}

div.normal-table table tbody a i, div#grid table tbody a i, div#grid1 table tbody a i, div#grid2 table tbody a i, div#grid3 table tbody a i, div#grid4 table tbody a i, div#grid5 table tbody a i {
  color: #121212!important;
  font-size: 1.2857142857em;
}

div.normal-table table tbody a i.active:hover, div#grid table tbody a i.active:hover, div#grid1 table tbody a i.active:hover, div#grid2 table tbody a i.active:hover, div#grid3 table tbody a i.active:hover, div#grid4 table tbody a i.active:hover, div#grid5 table tbody a i.active:hover {
  color: #121212!important;
}

div.normal-table table td, div#grid table td, div#grid1 table td, div#grid2 table td, div#grid3 table td, div#grid4 table td, div#grid5 table td {
  border: 0;
  color: #121212!important;
  font-size: 1em;
  font-family: "Open Sans", sans-serif, "DejaVu Sans", "sans-serif", "serif";
  padding-left: 25px;
  font-weight: 400;
}

div.normal-table table tbody a.btn.btn-form:hover i, div#grid table tbody a.btn.btn-form:hover i, div#grid1 table tbody a.btn.btn-form:hover i, div#grid2 table tbody a.btn.btn-form:hover i, div#grid3 table tbody a.btn.btn-form:hover i, div#grid4 table tbody a.btn.btn-form:hover i, div#grid5 table tbody a.btn.btn-form:hover i {
  color: #ffffff;
}

div.normal-table table tbody a.btn.btn-form:hover, div#grid table tbody a.btn.btn-form:hover, div#grid1 table tbody a.btn.btn-form:hover, div#grid2 table tbody a.btn.btn-form:hover, div#grid3 table tbody a.btn.btn-form:hover, div#grid4 table tbody a.btn.btn-form:hover, div#grid5 table tbody a.btn.btn-form:hover {
  color: #ffffff;
  background-color: #0079c2;
  border-color: #0079c2;
  border-radius: 0;
  -o-box-shadow: 0 0 5px #585858;
  box-shadow: 0 0 5px #585858;
}

p {
    margin:0;
    line-height: 1.2em;
    color: #767676;
}

#app-layout{
    background: #f5f5f5;
}

#main-content .container{
    width: auto;
    position: relative;
}

/*PAGINA DE LOGIN*/
.login-logo {
    background-color: #0079c2;
    border-radius: 5px 5px 0 0;
    height: 70px;
    border-bottom: 4px solid #37a1d5;
}

.logo{
    text-align: center;
    margin-bottom: 2em;
}

.login-box {
    margin-top:0;
    background: #ffffff;
    padding-bottom: 0;
    border-radius: 10px;
    /*-webkit-filter: drop-shadow(2px 2px 0px #e3e4e8);*/
}

.fb-login, .twit-login {
    display: none;
}

.login-form input, .login-form select{

    border: 2px solid #0079c2;
    font-size: 12px;
    min-height: 50px;
    padding: 12px;
    border-radius: 5px;
    width:100%;
}

.form-group {
    margin: 0 auto 15px;
    width: 85%;
}

.form-group label{
    font-weight: normal;
}

.login-form .ui-checkbox input {
    margin-left: -7px;
    margin-top: -5px;
    width: 22px;
}

.login-form .ui-checkbox label {
    text-align: center;
    padding-right: 0;
}

.login-form a{
    color: #6c6c6c;
}

.login-form a:hover{
    text-decoration: none;
}

.form-control.user, .form-control.password {
    background:#fff;
}

.login-form select {
    height: 3.5em;
}
.btn.btn-login {
    display: block;
    border: 2px solid rgba(0, 121, 194, 1);
    color: #0079C2;
    background: transparent;
    border-radius: 21px;
}

.btn-caja{

    background-color: #f6f8fa;
    padding:5px 0;
    border-radius: 0 0 10px 10px;
    margin-top: 20px;
}

.ui-page-theme-a .ui-btn:hover{

    background-color: white;

}
.recordar{

    color: #6c6c6c;
    text-decoration: none;
    font-size: 12px;
}

.forgot{
    float: left;
    left: 2%;
    position: relative;
    top: -10px;
    width: 40%;
}

.chek{
     border-right: 2px solid #0079C2;
    ;
 }

.ui-btn-corner-all, .ui-btn.ui-corner-all, .ui-slider-track.ui-corner-all, .ui-flipswitch.ui-corner-all, .ui-li-count{
    border-radius: 0;
}

a {
    text-decoration: none;
    color: #6c6c6c;}

a:hover {
    text-decoration: none;
    color: #0079C2;}

.ui-btn-icon-left {
    padding-left: 3em;
}


.btn.btn-login:hover {
    border: 2px solid #0079C2;
    background: #fff;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 8px solid;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    color:#0079C2;
}
/********MENU PRINCIPAL**
***************************/
.container-fluid {

    padding-left: 5px;
}

#sidebar {
    background-color: #efefef;
    left:-150px;
}

ul.sidebar-nav span{

    color:#70858e;
}

ul.sidebar-nav li.current span{

    color:#ffffff;

}

ul.sidebar-nav li:hover, ul.sidebar-nav li:active, ul.sidebar-nav li.current a, ul.sidebar-nav li.current i, ul.sidebar-nav li:hover span, ul.sidebar-nav li:hover i{
    background-color: #0079c2;
    color:#fff;
}

ul.sidebar-nav a:hover, ul.sidebar-nav li{
    text-decoration: none;
}

.sidebar-large #sidebar {
    width: 150px;
    line-height: 1;
    /*overflow: scroll;*/
}
.sidebar-large #wrapper {
    padding-left: 150px;
}
.sidebar-large #sidebar li a{ 
    text-align: left; 
    text-decoration: none;
    padding-left: 10px;
}

.ico {
    color: #70858e;
    font-family: ico_mediris,"Open Sans",sans-serif;
    font-size: 1.5em;
    font-style: normal;
    margin-right: 5%;

}

.sidebar-nav a i {
    padding-right: 0;
}

.arrow {
    margin-top: -1px;
    padding-right: 3px;
    padding-top: 16px;
}


.navbar-inverse .navbar-brand {
    background: url('/images/logo_tope.png') no-repeat center;
    /*margin-left: 97px;*/
    margin-left: 10%;
    /*position: absolute;*/
    position: relative;
    width: 190px;
}
.navbar-inverse {
     background-color: #ebebeb;
     border-bottom: 0;
 }

.sidebar-toggle{
    border:0;
}

.sidebar-toggle, .sidebar-toggle:hover  {
    color: #1c3e4e;
}
.sidebar-thin .sidebar-nav li a, .sidebar-medium .sidebar-nav li a, .sidebar-large .sidebar-nav li a {

    padding: 10px 0px;

}
.sidebar-medium .sidebar-nav li.current a i, .sidebar-thin .sidebar-nav li.current a i, .sidebar-large .sidebar-nav li.current a i {
    color: #ffffff;
}

.sidebar-medium .sidebar-nav li a i {

    font-size: 2.8em;

}
.sidebar-medium .sidebar-nav li a {
    line-height: 1;
}
/*iconos*/

.icon-principal:before {
    content: "\e606";
}

.icon-busqueda:before {
    content: "\e601";
}

.icon-administracion:before {
    content: "\e600";
}
.icon-citas:before {
    content: "\e602";
}
.icon-entrevista:before {
    content: "\e603";
}
.icon-pacientes:before {
    content: "\e604";
}

.icon-preingreso:before {
    content: "\e605";
}
.icon-radiologo:before {
    content: "\e607";
}
.icon-recepcion:before {
    content: "\e608";
}
.icon-reportes:before {
    content: "\e609";
}
.icon-resultados:before {
    content: "\e60a";
}
.icon-tecnico:before {
    content: "\e60b";
}
.icon-transcripcion:before {
    content: "\e60c";
}
.icon-configuracion:before {
    content: "\e991";
}
.icon-sistema:before {
    content: "\e994";
}
.icon-configuration:before {
    content: "\e603";
}
.navbar-center {
    color: #ff0026;
    font-size: 1em;
    text-align: center;
    width: 23%;
    margin-left: 18%;
    left: 0;
    top: 15px;
}

.header-menu .dropdown-menu {
    max-width: 650px !important;
    width: 650px;

}
.header-menu #user-header .dropdown-menu {
    width: 650px;
}
#clave input.form-control.form-margin {
    width: 80%;
    margin-left: 20px;
    margin-bottom: 5px;
}
p.m-b-20.form-margin {
    margin: 20px 0 5px 20px;
}

svg {

    height: auto;
}

#config-header .dropdown-menu > li > a {

    clear: none;
}
#config-header .icon-config{
    position:relative;
    float:left;
}
 ul.sidebar-nav .active .submenu span {
     border-left: 1px solid transparent;
     color: #ffffff;
     font-size: 0.9em;
     padding-left: 3px;
}

ul.sidebar-nav .active  a {
    background-color: #0079c2;
    color:#fff;
}

ul.sidebar-nav .active  a span{
    background-color: transparent;
    color:#fff;
}
ul.sidebar-nav .active  a i, .sidebar-medium .sidebar-nav li.active i {

    color:#fff;
}
#sidebar ul.submenu a {
    padding: 11px 10px 11px 20px;
}

.sidebar-medium #sidebar ul.submenu a, #sidebar ul.submenu a, .sidebar-thin #sidebar ul.submenu a {
    background-color: #1c3e4e;
}
.sidebar-medium #sidebar li.active:after, .sidebar-thin #sidebar li.active:after{
    border-color: rgba(0,162,217,0) #1c3e4e;

}

ul.sidebar-nav .active .submenu span:hover {
    /*color: #0079C2;*/
    border-left: 1px solid #0079C2;
}
/***************
****mensaje******
****************/

div.message_board {
    margin-top: 2%;
    border-bottom: 1px solid #0079C2;
    padding-bottom: 10px;
}

div.message_board.col-md-10 {
    width: 79.333333%;
}
/*estadisticas*/

.panel-title {
    font-size: 24px;
    width:100%;
}

/**CHARTS******/

.leyenda1{
    border-left: 3px solid #7d677b;
    padding-left: 10px;
}
.leyenda2{
    border-left: 3px solid #997795;
    padding-left: 10px;
}
.leyenda3{
     border-left: 3px solid #81c1b5;
     padding-left: 10px;
 }
.leyenda4{
      border-left: 3px solid #8fdace;
      padding-left: 10px;
  }
.leyenda5{
       border-left: 3px solid #67b0ce;
       padding-left: 10px;
   }


.bg-grey {
    background-color: #efefef ;
    color: #1c3e4e ;
}

.mm-ismenu {
    background: #EFEFEF;
    color: rgba(0,0,0,0.6);
}
#main-content {
    background-color: #f5f5f5;
    padding: 10px 0;
    position: absolute;
    top: 5%;
    width: 80%;
}

.azul{

    color: #0079c2 ;
}

.bg-royal-blue{
background-color: #1c3e4e;
    color:#ffffff;

}


.ui-page .navbar-inverse .navbar-nav>.open>a, .ui-page  .navbar-inverse .navbar-nav>.open>a:hover, .ui-page .navbar-inverse .navbar-nav>.open>a:focus { background-color: transparent;}
.mm-menu.mm-right{width:30%}
html.mm-right.mm-opening .mm-page{width: 70%;}
.mm-menu .mm-panel.mm-opened{width: 100%;}

div#section1 p, div#section2 p  {line-height:2em; color:#fff;}
.dropdown-menu .form-control{display: block!important;}
.dropdown-menu .bootstrap-select.form-control{display: none!important;}


.btn-azul{
color: #0090d9;
border: 1px solid #0090d9;
padding: 3px 6px;
border-radius: 2em;
    background-color: transparent;
}

#cambio-user{
    background-color: #efefef;

}

#cambio-user input {
   border-radius: .5em;
    border:2px solid #0090d9;


}
#cambio-user .btn-group {
    border-radius: .5em;
    border:2px solid #0090d9;


}
#cambio-user .btn-default {
    background-color: #FFFFFF;
    color: #B6B6B6 !important;
    cursor: pointer;
}


div#paciente h5{
    color:#0079c2;
}

.sombra{
    box-shadow: 0px 3px 0px #e3e4e8;
}

.titulo2 {
    font-size: 1.3em;
    font-weight: bold;
    color: #1c3e4e;
    padding-bottom: 8px;
}

.foto-paciente{
    height:auto; width:100%; border:2px solid #0079C2; border-radius:0.5em;
}

.marcos{
    border:2px solid #0079C2; border-radius:0.5em; min-height:1em;
}

#examen span{
    font-weight:bold;
    color:#0079C2;
}

#transcripcion textarea, #tecnico textarea {

    border: 0;
    background-color: #efefef;
    border-radius:.5em;
    width:100%;
    min-height:100px;

}

#transcripcion input, #tecnico input {

    border: 0;
    background-color: #efefef;
    border-radius:.5em;
    height:3em;
    padding-left: 10px;

}
#direccion {width: 100%;
    border: 1px solid #d3d3d3;}

#transcripcion .btn-default, #busq_resultados .btn-default, #paciente .btn-default, .dropdown-toggle.btn-default {
    color: #0079C2 !important;
}
.linea{
    height:1px;
    width:100%;
    background-color:#d6d6d6;
}

.bg-Lgrey {
    background-color: #d6d6d6 !important;
    color: #70858e !important;
}

.bg-Lgrey  h3{

    color: #70858e !important;
}

.linea_login{

    background-color:#d6d6d6;
}
#tecnico .btn-group{
    border:2px solid #d7d7d7;
    border-radius:.5em;

}
label.check.ui-btn.ui-corner-all.ui-btn-inherit.ui-btn-icon-left.ui-checkbox-off, label.check.ui-btn.ui-corner-all.ui-btn-inherit.ui-btn-icon-left.ui-checkbox-on{
    border-radius:.5em;
}
.check{
    border:2px solid #d7d7d7;
    width:200px;
    color:#0079c2;

}
.m-t-25 {
    margin-top: 25px !important;
}
#urgent .ui-checkbox{

    margin:0;
}

#tecnico input.m-b-10{
    width:100%;
}

#tecnico .btn-default {
    color: #767676 !important;
    background-color: #FFFFFF;
}

body.login.lockscreen {
    background: #f1f1f1;
    color: #0079c2;
}

.page-icon {

    background: #0079c2;
}

#icon img{
    width: 34px;
    height: 33px;
    margin-top: 33px;
    border-radius: 0;
}

.search-title{
    color:#0079c2;margin-top: 0; font-size:20px;
}

#busqueda_avanzada .btn-default {
    color: #767676 !important;
    background-color: #ffffff;
    border: 2px solid #e3e4e8;
}

#busqueda_avanzada input, #reportrange {
    border-radius: .5em;
}

#busqueda .btn-default, #b_avanzada .btn-default{

    color:#b6b6b6 !important;
    background-color: #fff;
    font-size: 14px;


}
.form-control{
    border: 2px solid #d3d3d3;
}

#busqueda label , #b_avanzada label {
    color: #767676;
}

#b_avanzada {

    display: none;

}

.bg-blue {
    background-color: #0079c2 !important;
}
.borde-top{border-top:1px solid #37a1d5;}

#t-resultados thead{
    background-color: #a4aeb9;
    color: #ffffff;
}
#t-resultados th {
    font-weight: normal;
    font-size: 0.9em;
}
.panel-default {
    border-color: transparent;
    margin-bottom: 0;
}
#t-resultados .panel-heading {  border-bottom: 0;  }

#t-resultados table#table-editable{  border-top: 3px solid #1c3e4e;  }

#t-resultados table.dataTable{  margin-top: 0 !important;}

#t-resultados table.dataTable thead > tr > th {
    padding-left: 9px;
    padding-right: 18px;
}
#t-resultados .panel-body {
    padding: 0;
}
#b_avanzada .form-control, #busqueda .form-control {
    border: 2px solid #d3d3d3;

}
label.check2.ui-btn.ui-corner-all.ui-btn-inherit.ui-btn-icon-left.ui-checkbox-off, label.check2.ui-btn.ui-corner-all.ui-btn-inherit.ui-btn-icon-left.ui-checkbox-on{border-radius: .5em; }

#observaciones {
    border: 0;
    background-color: #efefef;
    border-radius: .5em;
    width: 100%;
    min-height: 100px;
}
#categoria, #subcategoria {
    border: 0;
    background-color: #efefef;
    border-radius: .5em;
    width: 100%;
    min-height: 30px;
    padding-left: 10px;
}

/****************************
******* ICONOS TABLA ************
******************************/

#lock:hover{
    content: url("../images/icon_lock_on.png")
}

#lock {
    content: url("../images/icon_lock_off.png")
}

#eliminar:hover{
     content: url("../images/icon_eliminar_on.png")
 }

#eliminar {
    content: url("../images/icon_eliminar_off.png")
}

#print:hover{
    content: url("../images/icon_print_on.png")
}

#print {
    content: url("../images/icon_print_off.png")
}

#edit:hover{
    content: url("../images/icon_edit_on.png")
}

#edit {
    content: url("../images/icon_edit_off.png")
}

#see:hover{
    content: url("../images/icon_ver_on.png")
}

#see {
    content: url("../images/icon_ver_off.png")
}


.zone-drag{
    border: 2px solid #0079c2; border-radius:.5em; height: 377px; padding:10px;
    overflow-y: scroll;
}

.consumidos {width: 40px;}

#menu-bottom{ border-top: 1px solid #b8b8b8;}

#text-h {display:none; }

#chat-toggle:hover + #text-h{ display:block;}

audio { width: 90%;  float: right;}

.ui-btn-icon-left {
    padding-left: 2.5em;
}
.check2 {
    width: 220px;
    border:2px solid #d7d7d7;
    color:#0079c2;
    border-radius: .5em;
}

input[type="text "] {  left: 10.466em;   width: 30px;}

.btn-tabla {
    text-decoration: none;
    color: #0090d9;
    cursor: pointer;
}
.btn-tabla:hover {
    text-decoration: none;
    color: #1C3E4E;
    cursor: pointer;

}

.btn {
    padding: 6px 12px;
}
.mm-search{display:none;}
.mm-menu.mm-hassearch .mm-panel {
    padding-top: 10px;
}

.textarea_m{
    border: 0;
    background-color: #efefef;
    border-radius:.5em;
    width:100%;
    min-height:100px;
}
/****************************
*******TECNICO BLACK************
******************************/

.bg-black, #main-content.mc-black {background: #000000;}

.bd-dark{background-color: #202020 !important;
color: #ffffff !important;}

.sombra-dark {
    box-shadow: 0px 3px 0px #353535;
}

.titulo_dark {
    font-size: 1.3em;
    font-weight: bold;
    color: #0079c2;
    padding-bottom: 8px;
}

.panel_dark {
    margin-bottom: 20px;
    background-color: #202020;
    border: 1px solid transparent;
    border-radius: 4px;
    /*-webkit-box-shadow:*/
}

.container-fluid-dark {
    background-color: #373D47;
}


.c-text-dark {
    color: #A5A5A5;
    font-weight: 600;
    line-height: 2.9em;
}

.navbar-center-dark {
    color: #FFFFFF;
    font-size: 1em;
    text-align: center;
    width: 23%;
    margin-left: 18%;
    left: 0;
    top: 15px;
    display: inline-block;
}

nav#sidebar.sidebar-dark {
    background-color: #33373E;
}

.ico-dark {
    font-size: 2.8em;
    font-family: ico_mediris, 'Open Sans', sans-serif;
    font-style: normal;
    color: #A5A5A5;
}

ul.txtdark  span{color: #A5A5A5;}

#transcripcion-dark textarea{
    border: 0;
    background-color: #42464D;
    border-radius: .5em;
    width: 100%;
    min-height: 100px;
}
.check-dark{
    border:2px solid #0079c2;
    width:200px;
    color:#0079c2;
    background-color:#2B2E33 !important;
    border-radius: .5em !important;

}

.linea-dark {
    height: 1px;
    width: 100%;
    background-color: #42464D;
}

.btn-default-dark, #exam button.btn-default, #bottom-menu-dark button.btn-default{
    color: #DCDDDC !important;
    background-color: #42464D;
    border-color: #42464D;
}
#exam div.form-control, #bottom-menu-dark div.form-control {background-color: transparent!important;}

#bottom-menu-dark input.form-control, #transcripcion-dark input{
    background-color: #42464D !important;
    border: 1px solid #373D47;
}

#transcripcion-dark input{ padding:7px; border-radius: .5em;}

#menu-right .panel-body-dark {
    background-color: #42464D;
    padding: 26px;
}

.mm-right-dark {
    background: #2B2E33 !important;
    color: rgba(0,0,0,0.6);
}

.panel-dark {

    background-color: transparent;

}

/****************************
****** RECEPTION ************
******************************/

.title-label {
    display: block;
    margin: 15px 0 5px 0;
}

.reception-group {
    margin-bottom: 10px !important;
}

.space-bottom {
    margin-bottom: 25px !important;
}

/****************************
****** APPONTMENTS **********
******************************/

.search_bar {
    margin-top: 10px;
    max-height: 36px;
}

.special_top {
    margin-top: 17.5px !important;
    text-align: right !important;
}

.k-pager-sizes .bootstrap-select {
    display: none !important;
}

span.k-dropdown .k-dropdown-wrap {
    height: inherit !important;
}

.k-filter-menu .k-button {
    padding: 0 15px !important;
    display: block !important;
    width: 100% !important;
}

/****************************
**** Module Textarea ********
******************************/

.big-textarea-plantilla table.k-editor {
    height: 500px;
}

/****************************
*******RESPONSIVE************
******************************/

@media screen and (max-width: 1209px) {



    .sidebar-medium .sidebar-nav li a i {

        font-size: 2.8em;

    }
    .sidebar-nav a i {
        padding-right: 0;
    }
    .sidebar-medium .sidebar-nav li.current a i, .sidebar-thin .sidebar-nav li.current a i, .sidebar-large .sidebar-nav li.current a i {
        color: #ffffff;
    }
    .sidebar-thin .sidebar-nav li a, .sidebar-medium .sidebar-nav li a, .sidebar-large .sidebar-nav li a {

        padding: 10px 0px;

    }
}

@media screen and (min-width: 1210px) {


    .sidebar-medium .sidebar-nav li a i {

        font-size: 2.8em;

    }
    .sidebar-nav a i {
        padding-right: 0;
    }
    .sidebar-medium .sidebar-nav li.current a i, .sidebar-thin .sidebar-nav li.current a i, .sidebar-large .sidebar-nav li.current a i {
        color: #ffffff;
    }
    .sidebar-thin .sidebar-nav li a, .sidebar-medium .sidebar-nav li a, .sidebar-large .sidebar-nav li a {

        padding: 10px 0px;

    }
}

@media screen and (max-width: 990px) {
    .login-form .ui-checkbox input{
        top:0;
    }

    .chek {
        border-right: medium none;
    }

    .ui-checkbox {
        display: inline;
    }
    
    .forgot {
        float: none;
        left: 0;
        margin: 0 auto;
        text-align: left;
        top: 5px;
        width:75%;
    }
}

.k-editor-toolbar-wrap .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    display:none;
}
.k-picker-wrap.k-state-default {
    height: 26px !important;
}

#loader {
    z-index: 1;
    width: 25px;
    height: 25px;
    border: 2px solid #d9d9db;
    border-radius: 50%;
    border-top: 2px solid #A40909;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    background-color:red;
    display:none;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Using plain image for background */

.k-editor .k-i-preview {
    background: url("/img/loupe.png") 50% 80% no-repeat;
}

.k-editor .k-i-print {
    background: url("/img/print.png") 50% 80% no-repeat;
}

.alert {
    font-size: 1em!important;
    margin-top: 0!important;
    margin-bottom: 1em!important;
    margin-left: 1em!important;
    width: 99%!important;

}
